<template>
  <FixedRatioContainer>
    <div v-if="user" class="grid-box p-8 mx-auto w-full h-full overflow-scroll no-scrollbar" style="max-width: 500px">
      <img v-if="user.profile_picture"
           :src="$store.getters['common/baseURL'] + user.profile_picture"
           class="mw-200 mx-auto d-block object-cover mt-10" style="border-radius: 50%; height: 140px; width: 140px;"/>
      <div v-else style="height: 100px; width: 100px; border-radius: 50%;"
               class="bg-gray-300 flex items-center justify-center mx-auto">
        <i class="fas fa-user text-white" style="font-size: 50px"></i>
      </div>
      <div class="mt-4 font-bold text-2xl text-center">{{ user.username }}</div>
      <div class="text-center my-2">
        {{ user.location_string }}
      </div>
      <div v-if="$store.getters['account/has_premium']" class="text-center mt-1 text-blue">{{
          $t('userDetail.congruence') }}: {{
          user.character_congruence
        }}%
      </div>
      <div @click="$store.commit('common/setShowPremiumModal', { status: true });" v-else class="cursor-pointer text-center mt-2 font-bold text-blue">
        {{ $t('userDetail.getPremium') }}
      </div>

      <div v-if="user.profile_text" class="bg-lightgray p-2 rounded-xl mt-4 text-center">
        {{ user.profile_text }}
      </div>

      <div v-if="selected_activity" class="flex flex-col mt-5 text-sm">
        <div>
          <span style="color: rgb(173 184 216)">{{ $t('userDetail.activity', {name: user.username}) }}: </span> {{
            selected_activity.name
          }}
        </div>
        <div class="flex justify-between">
          <div class="flex justify-center items-center font-bold text-3xl
           cursor-pointer pr-5" @click="next_activity">
            <i class="fas fa-angle-left"></i>
          </div>
          <div class="relative mt-2 flex-grow" style="height: 150px">
            <img :src="$store.getters['common/baseURL'] + selected_activity.image"
                 class="object-cover h-full w-full mx-auto rounded-xl"
                 @click="next_activity"/>
          </div>
          <div class="flex justify-center items-center font-bold text-3xl
           cursor-pointer pl-5" @click="previous_activity">
            <i class="fas fa-angle-right"></i>
          </div>
        </div>

      </div>

      <div v-if="user.username !== $store.getters['account/username']" class="px-6 mt-4">
        <div v-if="$store.getters['chat/room_for'](user.username) === null" class="text-center">
          <textarea v-model="message" :placeholder="$t('userDetail.placeholder')"
                    class="rounded-xl mt-2 w-full bg-lightgray p-2"
                    style="height: 80px; border: 1px solid rgb(227, 227, 227)"/>

          <button class="py-4 px-6 bg-blue text-white rounded-xl mt-4 cursor-pointer" @click="request_chat_room()">
            {{ $t('userDetail.send') }}
          </button>
        </div>
        <div v-else-if="$store.getters['chat/room_for'](user.username).target.status === 'awaiting_approval'"
             class="mt-5 text-blue text-center font-bold text-lg">
          {{ $t('userDetail.awaitingApproval') }}
        </div>
        <div v-else class="text-center">
          <button @click="toggleChat"
                  class="py-4 bg-blue text-white rounded-xl cursor-pointer"
                  style="width: 150px">
            {{ $t('userDetail.chat') }}
          </button>
        </div>
      </div>
    </div>
  </FixedRatioContainer>
  <GetPremium v-if="$store.getters['common/showPremiumModal']"></GetPremium>
</template>

<script>
import axios from '../axios';
import FixedRatioContainer from "@/components/FixedRatioContainer";
import GetPremium from "@/components/GetPremium";

export default {
  components: {
    FixedRatioContainer,
    GetPremium
  },
  watch: {
    '$store.state.users.selected_user': {
      handler: function() {
        this.set_user();
      }
    }
  },
  data() {
    return {
      user: null,
      user_activities: null,
      selected_activity_index: null,
      message: ""
    }
  },
  computed: {
    selected_activity() {
      if (this.selected_activity_index !== null) {
        return this.user_activities[this.selected_activity_index];
      }
      return null;
    }
  },
  methods: {
    set_user() {
      const activity_map = this.$store.getters['activity/activity_map'];
      const user = this.$store.getters['users/selected_user'];
      const user_activities = [];

      for (const id of user.liked_activities) {
        user_activities.push(activity_map[id]);
      }

      let selected_activity_index = null;
      if (user_activities.length > 0) {
        selected_activity_index = 0;
      }

      this.user = user;
      this.user_activities = user_activities;
      this.selected_activity_index = selected_activity_index;
    },
    request_chat_room() {
      axios.post('/account/friendship/create/', {
        message: this.message,
        username: this.user.username
      });
    },
    next_activity() {
      this.selected_activity_index = (this.selected_activity_index + 1) % this.user_activities.length;
    },
    previous_activity() {
      --this.selected_activity_index;
      if(this.selected_activity_index < 0) {
        this.selected_activity_index = this.user_activities.length-1;
      }
    },
    toggleChat() {
      this.$store.commit('chat/set_current_user_from_user_detail_page', { username: null });
      this.$store.commit('chat/set_current_user_from_user_detail_page', { username: this.user.username });
    }
  },
  mounted() {
    if(!this.$store.getters['users/selected_user']) {
      this.$router.push('/home');
      return;
    }
    this.set_user();
  }
}
</script>